<template>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol col="12" xl="12">
            <v-table id="loginHistoryTable" :columns="columns" :data="data" :options="options"></v-table>
          </CCol>
        </CRow>
      </CCardBody>
  </CCard>
</template>

<script>

import loopback from '@/services/loopback';

import { LOGINHISTORY_TABLE_OPTIOINS, LOGINHISTORY_TABLE_COLUMS }
  from "@/constants/loginHistroySettings"
import utils from '@/services/utils'

export default {
  name: 'LoginHistory',
  created() {
    _.find(this.columns, { field: 'created_at' }).formatter = utils.dateFormatter;
    this.query();
  },
  data () {
    var table_options = _.cloneDeep(LOGINHISTORY_TABLE_OPTIOINS);
    table_options.search = false;
    return {
      options: table_options,
      columns: _.cloneDeep(LOGINHISTORY_TABLE_COLUMS),
      data: [],
    }
  },
  methods: {
    query() {
      var self = this;
      return loopback.get('/loginlogs')
        .then(res => {
          self.data = [];
          res.forEach(function(row, index0) {
            self.data.push({
              created_at: row.created_at,
              user_id: row.user_id,
              user_name: row.user_name,
              service_type: row.service_type,
            })
          })
          return Promise.resolve(self.data)
        })
    },
  }
}
</script>